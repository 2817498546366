import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { nodeService } from "../../services";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

const NodesChart = () => {
  const [nodesLength, setNodesLength] = useState([]);
  const [stmLength, setStmLength] = useState([]);
  const [espLength, setEspLength] = useState([]);

  const [loading, setLoading] = useState(true);

  const [datas, setDatas] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const option = (name, data) => {
    return {
      title: {
        text: name,
        x: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
        formatter: function (value) {
          return `${value} : ${data?.find((item) => item?.name === value).value}`;
        },
      },
      series: [
        {
          name: name,
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: true,
            position: "inside",
            formatter: "{c}",
          },

          labelLine: {
            show: true,
          },
          data: data,
        },
      ],
    };
  };

  const getCompanyDatas = async () => {
    setLoading(true);
    const data = await nodeService.listNodesByCompany().then((res) => {
      return res.data;
    });

    const companyLength = data.map((item) => {
      return {
        name: item?.name,
        value: item?.nodes?.length,
      };
    });

    const groupByVersionSTM = (data) => {
      return data.reduce((acc, device) => {
        const key = `${device.stmVersion}`;
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key]++;
        return acc;
      }, {});
    };

    const versions = data.map((item) => {
      return groupByVersionSTM(item.nodes);
    });

    const stmData = {};

    versions.forEach((record) => {
      Object.entries(record).forEach(([name, value]) => {
        if (stmData[name]) {
          stmData[name] += value;
        } else {
          stmData[name] = value;
        }
      });
    });

    const stmResult = Object.entries(stmData).map(([name, value]) => ({
      name,
      value,
    }));

    const groupByVersionESP = (data) => {
      return data.reduce((acc, device) => {
        const key = `${device.espVersion}`;
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key]++;
        return acc;
      }, {});
    };

    const versionsEsp = data.map((item) => {
      return groupByVersionESP(item.nodes);
    });

    const ESPData = {};

    versionsEsp.forEach((record) => {
      Object.entries(record).forEach(([name, value]) => {
        if (ESPData[name]) {
          ESPData[name] += value;
        } else {
          ESPData[name] = value;
        }
      });
    });

    const espResult = Object.entries(ESPData).map(([name, value]) => ({
      name,
      value,
    }));

    const datas = [];

    datas.push(companyLength);
    datas.push(stmResult);
    datas.push(espResult);
    setDatas(datas);
    setLoading(false);
  };

  useEffect(() => {
    getCompanyDatas();
  }, []);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex flex-row items-center justify-start border-b pl-6 pb-3">
        <div
          onClick={() => {
            navigate("/dashboard/system-node");
          }}
          className="cursor pointer w-[24px] h-[20px]"
        >
          <ArrowLeftIcon className="w-6 h-6 text-gray-700 " />
        </div>
        <span className="text-secondary-900 text-[28px] font-medium ml-6 select-none"> Node List Chart View </span>{" "}
      </div>

      <div className="w-full flex items-center flex-wrap h-full">
        {loading ? (
          <div className="flex gap-x-5 h-full items-start w-full mt-6">
            <div className="w-1/3 h-[400px] animate-pulse rounded-lg bg-slate-300"> </div>
            <div className="w-1/3 h-[400px] animate-pulse rounded-lg bg-slate-300"> </div>
            <div className="w-1/3 h-[400px] animate-pulse rounded-lg bg-slate-300"> </div>
          </div>
        ) : (
          <>
            <div className="w-1/3 h-full">
              <ReactECharts
                //   onEvents={onEvents}
                option={option("Nodes", datas[0])}
                style={{
                  height: "80%",
                  width: "98%",
                  top: "8px",
                  marginLeft: "8px",
                  marginTop: "36px",
                }}
              />
            </div>
            <div className="w-1/3 h-full">
              <ReactECharts
                //   onEvents={onEvents}
                option={option("STM Versions", datas[1])}
                style={{
                  height: "80%",
                  width: "98%",
                  top: "8px",
                  marginLeft: "8px",
                  marginTop: "36px",
                }}
              />
            </div>
            <div className="w-1/3 h-full">
              <ReactECharts
                //   onEvents={onEvents}
                option={option("ESP Versions", datas[2])}
                style={{
                  height: "80%",
                  width: "98%",
                  top: "8px",
                  marginLeft: "8px",
                  marginTop: "36px",
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NodesChart;
