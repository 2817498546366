import React, { Fragment, useState } from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { SettingsIcon, ToolsIcon, UpuLogo, MenuIcon } from "../../assets/icons";
import { LoginIcon } from "@heroicons/react/outline";
import { useStore } from "../../hooks/useStores";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../utils/generateFileUrl";

const DashboardNavigation = observer(({ routes }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { auth } = useStore();
  const navigate = useNavigate();
  const logout = () => {
    auth?.logOut();
    navigate("/auth/login");
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="h-screen w-screen flex test xs:hidden">
        {/* Static sidebar for desktop */}
        <div className="flex flex-shrink-0 no-scrollbar xs:hidden">
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`flex flex-col top-0 bottom-0 transition-all no-scrollbar ${
              isHovered ? "w-[260px]" : "w-20"
            }`}
          >
            <div className="flex-1 flex flex-col min-h-0 overflow-y-auto no-scrollbar bg-[#6941C6]">
              <div className="flex-1">
                <NavLink
                  to="/dashboard"
                  className={`bg-[#6941C6] items-center mt-8 gap-2 flex group  ${
                    !isHovered
                      ? "items-center justify-center"
                      : "pl-4 items-center ml-3"
                  }`}
                >
                  <UpuLogo className="w-8 h-8" />
                  {isHovered && (
                    <label className="text-bold font-bold text-2xl -mb-2 text-white cursor-pointer">
                      upu.io
                    </label>
                  )}
                </NavLink>
                <nav
                  aria-label="Sidebar"
                  className={`py-6 mx-2 flex flex-col gap-2 mt-3 ${
                    !isHovered ? "items-center" : "pl-1.5 pr-1.5"
                  } space-y-1`}
                >
                  {routes.map((item, i) => (
                    <NavLink
                      key={i}
                      to={item.path}
                      className={`flex items-center py-3 px-2 rounded-lg text-primary-300 hover:bg-[#7f52e6] whitespace-nowrap gap-2`}
                      style={{ display: item.display }}
                    >
                      {<item.icon />}
                      {isHovered && (
                        <span className="text-[#F4EBFF] text-base whitespace-nowrap font-light">
                          {t(`routes:${item.name}`)}
                        </span>
                      )}
                    </NavLink>
                  ))}
                </nav>
              </div>
              <div
                className={`flex pb-5 w-full justify-around items-center select-none ${
                  isHovered &&
                  "justify-start gap-3 border-t border-primary-600 pt-4"
                }`}
              >
                {isHovered && (
                  <>
                    {auth?.user?.avatar ? (
                      <img
                        src={generateFileUrl(auth?.user?.avatar)}
                        className={
                          "w-10 h-10 rounded-full border-2 border-primary-200"
                        }
                        alt=""
                      />
                    ) : (
                      <div
                        className={
                          "w-11 h-11 rounded-full bg-white flex items-center justify-center text-primary-600 text-xl leading-none font-medium border-4 border-stone-700 shadow"
                        }
                      >
                        {auth?.user?.name?.charAt(0).toUpperCase()}
                      </div>
                    )}
                    <span
                      onClick={() => {
                        navigate("/change-password");
                      }}
                      className={`cursor-pointer text-sm text-[#E9D7FE] whitespace-nowrap`}
                    >
                      {auth?.user?.name} {auth?.user?.lastName}
                    </span>
                  </>
                )}
                <span
                  to="/auth/login"
                  className={`w-10 h-10 rounded-full bg-primary-100 border-4 cursor-pointer border-primary-50 flex items-center justify-center `}
                >
                  <LoginIcon
                    onClick={() => logout()}
                    width={22}
                    height={22}
                    className={`text-primary-600 ${isHovered && "w-5 h-5"}`}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1  min-w-0 flex flex-col overflow-hidden">
          <main className="flex-1 flex overflow-hidden">
            <section
              aria-labelledby="primary-heading"
              className="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last bg-[#6941C6]"
            >
              <h1 id="primary-heading" className="sr-only">
                Account
              </h1>
              <div className="w-full h-[98.5vh] bg-white flex bottom-0 mt-auto rounded-tl-[40px] p-4">
                <Outlet />
              </div>
            </section>
          </main>
        </div>
      </div>

      {/* mobile wiew */}

      <div className="w-screen h-screen flex-col hidden xs:flex">
        <div className="w-full h-16 bg-primary-700 flex justify-between px-4 items-center">
          <NavLink
            to="/dashboard"
            className={`bg-[#6941C6] items-center flex gap-1 `}
          >
            <UpuLogo className="w-8 h-8" />
            <label className="text-bold font-bold text-2xl -mb-1 text-white cursor-pointer">
              upu.io
            </label>
          </NavLink>
          <div>
            <Menu
              as={"div"}
              className={"flex flex-row-reverse items-center gap-3"}
            >
              <Menu.Button
                className={
                  "w-9 h-9 rounded-lg flex items-center justify-center bg-primary-100"
                }
              >
                <MenuIcon className=" w-5 h-5 text-white" />
              </Menu.Button>
              {/* Use the `Transition` component. */}
              <Transition
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-50 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items
                  className={
                    "flex justify-between items-center w-min h-9 rounded-lg bg-primary-100"
                  }
                >
                  {routes.map((item, i) => (
                    <NavLink
                      key={i}
                      to={item.path}
                      className={` mx-1 border bg-primary-700 rounded-lg w-8 h-8 flex items-center justify-center `}
                      style={{ display: item.display }}
                    >
                      {<item.icon width={22} height={22} />}
                    </NavLink>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          <div className="w-full h-full bg-white flex rounded-tl-[40px] p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
});

export default DashboardNavigation;
