import React, { useEffect, useState } from "react";
import { nodeService, companyService, userService } from "../../../services";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useAlert, positions } from "react-alert";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../../hooks/useStores";
import { BsTrash } from "react-icons/bs";
import {
  countries,
  lang,
  accountTypes,
  status,
  solutions,
  companyTableFilter,
  companyTabs,
  companyUserListTab,
} from "../../../static/constants";
import {
  AddUsers,
  DownArrow,
  DownIcon,
  NodeIcon,
  TrashIcons,
} from "../../../assets/icons";
import {
  ImageUploader,
  AdminModal,
  AlertModal,
  NoData,
  Select,
  PhoneInputs,
  SelectBox,
  Input,
  NewDatePicker,
  ChooseCompany,
} from "../../../components";
const CompanyForm = ({
  setIsOpen,
  industries,
  selectedCompany,
  setSelectedCompany,
  getAllCompanies,
  ccounts,
}) => {
  const { app } = useStore();
  const alert = useAlert();
  const [isOpenCompanySelect, setIsOpenCompanySelect] = useState(false);
  const [timeZonesOptions, setTimezoneOptions] = useState([]);
  const getTimezoneList = (id) => {
    companyService
      .getTimezoneList(id)
      .then((res) => {
        setTimezoneOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  const countryOptions = countries.map((country) => {
    return {
      label: country.name,
      value: country.code,
    };
  });
  const [selectedTab, setSelectedTab] = useState("companyInfo");
  const { t } = useTranslation();
  const [companyNodes, setCompanyNodes] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const schema = yup.object({
    image: yup.string().notRequired(),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    address: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    kdv: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    phone: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    fiscalYearStart: yup
      .date()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    fiscalYearEnd: yup
      .date()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    contactName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactLastname: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactEmail: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactPhone: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contractNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    taxOffice: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    taxNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    status: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    solutions: yup
      .array()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    country: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    cCount: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    timeZone: yup.string().required(`${t("validation:empty")}`),
    language: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    industryId: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    t1Standard: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t1: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t2: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t3: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    node: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    user: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    department: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    junction: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    message: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    notification: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    operation: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    poll: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    task: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    stockCardOperation: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    embeddedServer: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    externalOperation: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    supplier: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    order: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    stockCard: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    attend: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    shift: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: "",
      image: "",
      name: "",
      kdv: 20,
      address: "",
      email: "",
      phone: "",
      t1Standard: "",
      t1: "",
      t2: "",
      t3: "",
      solutions: [],
      accountType: "demo",
      fiscalYearStart: new Date(),
      fiscalYearEnd: new Date(),
      contactName: "",
      contactLastname: "",
      contactEmail: "",
      contactPhone: "",
      contractNo: "",
      taxOffice: "",
      taxNo: "",
      status: "registered",
      country: "TR",
      timeZone: "Europe/Istanbul",
      cCount: "",
      node: 0,
      user: 0,
      poll: 0,
      junction: 0,
      operation: 0,
      message: 0,
      department: 0,
      notification: 0,
      task: 0,
      stockCardOperation: 0,
      embeddedServer: 0,
      attend: 0,
      stockCard: 0,
      order: 0,
      supplier: 0,
      externalOperation: 0,
      shift: 0,
      language: "tr",
      industryId: "00000000-0000-0000-0000-000000000000",
    },
  });
  useEffect(() => {
    if (selectedCompany) {
      getTimezoneList();
      setValue("image", selectedCompany.image);
      setValue("kdv", selectedCompany?.vatValue);
      setValue("name", selectedCompany.name);
      setValue("address", selectedCompany.address);
      setValue("email", selectedCompany.email);
      setValue("phone", selectedCompany.phone);
      setValue("solutions", selectedCompany.solutions);
      setValue("t1Standard", selectedCompany.feeCoefficients.t1Standard);
      setValue("t1", selectedCompany.feeCoefficients.t1);
      setValue("t2", selectedCompany.feeCoefficients.t2);
      setValue("t3", selectedCompany.feeCoefficients.t3);
      setValue("accountType", selectedCompany.accountType);
      setValue("fiscalYearStart", new Date(selectedCompany.fiscalYearStart));
      setValue("fiscalYearEnd", new Date(selectedCompany.fiscalYearEnd));
      setValue("contactName", selectedCompany.contactName);
      setValue("contactLastname", selectedCompany.contactLastname);
      setValue("contactEmail", selectedCompany.contactEmail);
      setValue("contactPhone", selectedCompany.contactPhone);
      setValue("contractNo", selectedCompany.contractNo);
      setValue("taxOffice", selectedCompany.taxOffice);
      setValue("taxNo", selectedCompany.taxNo);
      setValue("status", selectedCompany.status);
      setValue("country", selectedCompany.country);
      setValue("timeZone", selectedCompany.timeZone);
      setValue("language", selectedCompany.language);
      setValue("industryId", selectedCompany?.industry?.id);
      setValue("id", selectedCompany.id);
      setValue("node", selectedCompany?.limits?.node);
      setValue("user", selectedCompany?.limits?.user);
      setValue("junction", selectedCompany?.limits?.junction);
      setValue("message", selectedCompany?.limits?.message);
      setValue("task", selectedCompany?.limits?.task);
      setValue("poll", selectedCompany?.limits?.poll);
      setValue("department", selectedCompany?.limits?.department);
      setValue("notification", selectedCompany?.limits?.notification);
      setValue("operation", selectedCompany?.limits?.operation);
      setValue(
        "stockCardOperation",
        selectedCompany?.limits?.stockCardOperation
      );
      setValue("embeddedServer", selectedCompany?.limits?.embeddedServer);
      setValue("externalOperation", selectedCompany?.limits?.externalOperation);
      setValue("supplier", selectedCompany?.limits?.supplier);
      setValue("order", selectedCompany?.limits?.order);
      setValue("stockCard", selectedCompany?.limits?.stockCard);
      setValue("attend", selectedCompany?.limits?.attend);
      setValue("shift", selectedCompany?.limits?.shift);
      setValue("cCount", selectedCompany?.cCount?.id);
      nodesByCompany(selectedCompany.id);
    } else {
    }
    getTimezoneList();
  }, [selectedCompany, setSelectedCompany]);
  const deleteCompany = (id) => {
    companyService
      .deleteCompany(id)
      .then((res) => {
        if (res.data.code === 0) {
          app.setLoadingOverlay(false);
          alert.success("Deleted.", {
            position: positions.TOP_LEFT,
          });
          getAllCompanies();
          setIsOpen(false);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  const getOneCompany = async () => {
    await companyService
      .getCompany(selectedCompany.id)
      .then((res) => {
        setSelectedCompany(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteUser = () => {
    app.setLoadingOverlay(true);
    userService
      .removePersonal(selectedUser.id, { companyId: selectedCompany.id })
      .then((res) => {
        if (res.data.code === 0) {
          app.setLoadingOverlay(false);
          alert.success("Deleted.", {
            position: positions.TOP_LEFT,
          });
          getOneCompany();
          setIsAlert(false);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  const nodesByCompany = (id) => {
    app.setLoadingOverlay(true);
    nodeService
      .findByCompany(id)
      .then((res) => {
        if (res.data.status === 1) {
          app.setLoadingOverlay(false);
          setCompanyNodes(res.data.data);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  const onSubmit = (data) => {
    app.setLoadingOverlay(true);
    if (selectedCompany && selectedCompany.id) {
      companyService
        .updateCompany(selectedCompany.id, {
          ...data,
          limits: {
            node: data?.node,
            user: data?.user,
            junction: data?.junction,
            message: data?.message,
            operation: data?.operation,
            task: data?.task,
            department: data?.department,
            poll: data?.poll,
            notification: data?.notification,
            stockCardOperation: data?.stockCardOperation,
            embeddedServer: data?.embeddedServer,
            externalOperation: data?.externalOperation,
            supplier: data?.supplier,
            order: data?.order,
            stockCard: data?.stockCard,
            attend: data?.attend,
            shift: data?.shift,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            getAllCompanies();
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
            getAllCompanies();
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      companyService
        .addCompany({
          ...data,
          limits: {
            node: data?.node,
            user: data?.user,
            junction: data?.junction,
            message: data?.message,
            operation: data?.operation,
            task: data?.task,
            department: data?.department,
            poll: data?.poll,
            notification: data?.notification,
            stockCardOperation: data?.stockCardOperation,
            embeddedServer: data?.embeddedServer,
            externalOperation: data?.externalOperation,
            supplier: data?.supplier,
            order: data?.order,
            stockCard: data?.stockCard,
            attend: data?.attend,
            shift: data?.shift,
          },
        })
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
            getAllCompanies();
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
            getAllCompanies();
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };

  const logContent = [{ content: "Log kaydi yok." }];

  return (
    <>
      <div className="w-full mt-0 pb-2 px-1 no-scrollbar overflow-hidden">
        <div className="w-full flex items-center justify-between border-b border-gray-200 overflow-hidden">
          {companyTabs.map((tab, i) => {
            if (tab.mandatory || selectedCompany) {
              return (
                <button
                  className={`${
                    selectedTab === tab.key
                      ? "border-primary-700 text-primary-700"
                      : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300/50"
                  } ${
                    selectedCompany ? " w-[calc(100%/5)]" : " w-[calc(100%/3)]"
                  } border-b-2 pb-2.5 text-sm font-semibold`}
                  key={i}
                  id={tab.id}
                  onClick={() => {
                    setSelectedTab(tab.key);
                  }}
                >
                  {t(`${tab.title}`)}
                </button>
              );
            }
          })}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"w-full overflow-y-scroll"}
        >
          <div className="w-full mt-6">
            {selectedTab === "companyInfo" && (
              <div className=" w-full flex flex-col">
                <div className="w-full flex gap-4">
                  <div className="w-1/2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <ImageUploader
                          title={t("fileInput:uploadAvatar")}
                          subTitle={t("fileInput:removePicture")}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                      name="image"
                    />
                  </div>
                  <div className="w-1/2 flex items-center justify-end pr-1.5">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={"KDV"}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.kdv ? errors.kdv.message : ""}
                          errorMessage={errors.kdv ? errors.kdv.message : ""}
                        />
                      )}
                      name="kdv"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-6">
                  <div className="w-full flex flex-col items-center justify-between gap-y-2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label="ID"
                          width={480}
                          value={value}
                          disabled={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.id ? "error" : ""}
                          errorMessage={errors.id ? errors.id.message : ""}
                        />
                      )}
                      name="id"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("dashboard:companyName")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.name ? errors.name.message : ""}
                          errorMessage={errors.name ? errors.name.message : ""}
                        />
                      )}
                      name="name"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("dashboard:adress")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.address ? "error" : ""}
                          errorMessage={
                            errors.address ? errors.address.message : ""
                          }
                        />
                      )}
                      name="address"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("dashboard:company") + " " + "E-mail"}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.email ? "error" : ""}
                          errorMessage={
                            errors.email ? errors.email.message : ""
                          }
                          inputType={"email"}
                        />
                      )}
                      name="email"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("company:industry")}
                          onBlur={onBlur}
                          width={480}
                          items={industries}
                          onChange={(v) => {
                            setValue("industryId", v);
                          }}
                          value={value}
                          validate={errors.industryId ? "error" : ""}
                          errorMessage={
                            errors.industryId ? errors.industryId.message : ""
                          }
                        />
                      )}
                      name="industryId"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <NewDatePicker
                          placeholder={t("form:selectDate")}
                          label={t("company:fiscalYear")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.fiscalYearStart ? "error" : ""}
                          errorMessage={
                            errors.fiscalYearStart
                              ? errors.fiscalYearStart.message
                              : ""
                          }
                        />
                      )}
                      name="fiscalYearStart"
                    />
                  </div>
                  <div className="w-full flex flex-col items-center justify-between gap-y-2">
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInputs
                          label={t("form:companyPhone")}
                          value={value}
                          onChange={onChange}
                          defaultCountry="TR"
                        />
                      )}
                      name="phone"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("dashboard:country")}
                          items={countryOptions}
                          width={480}
                          onBlur={onBlur}
                          onChange={(v) => {
                            setValue("country", v);
                          }}
                          value={value}
                          validate={errors.country ? "error" : ""}
                          errorMessage={
                            errors.country ? errors.country.message : ""
                          }
                        />
                      )}
                      name="country"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("company:timeZone")}
                          items={timeZonesOptions}
                          onBlur={onBlur}
                          width={480}
                          onChange={(v) => {
                            setValue("timeZone", v);
                          }}
                          value={value}
                          validate={errors.timeZone ? "error" : ""}
                          errorMessage={
                            errors.timeZone ? errors.timeZone.message : ""
                          }
                        />
                      )}
                      name="timeZone"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("auth:language")}
                          onBlur={onBlur}
                          width={480}
                          items={lang}
                          onChange={(v) => {
                            setValue("language", v);
                          }}
                          value={value}
                          validate={errors.language ? "error" : ""}
                          errorMessage={
                            errors.language ? errors.language.message : ""
                          }
                        />
                      )}
                      name="language"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("dashboard:status")}
                          items={status}
                          width={480}
                          onBlur={onBlur}
                          onChange={(v) => {
                            setValue("status", v);
                          }}
                          value={value}
                          validate={errors.status ? "error" : ""}
                          errorMessage={
                            errors.status ? errors.status.message : ""
                          }
                        />
                      )}
                      name="status"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <NewDatePicker
                          placeholder={t("form:selectDate")}
                          label={t("company:fiscalYearEnd")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.fiscalYearEnd ? "error" : ""}
                          errorMessage={
                            errors.fiscalYearEnd
                              ? errors.fiscalYearEnd.message
                              : ""
                          }
                        />
                      )}
                      name="fiscalYearEnd"
                    />
                  </div>
                </div>
                <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => deleteCompany(selectedCompany.id)}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                  >
                    {t("buttons:delete")}
                  </button>
                </div>
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:stop")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedTab("contactInfo");
                    }}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:next")}
                  </button>
                </div>
              </div>
            )}
            {selectedTab === "contactInfo" && (
              <div className="w-full">
                <div className="flex w-full gap-4">
                  <div className="w-full flex flex-col items-center justify-between gap-y-2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("routes:ccount")}
                          onBlur={onBlur}
                          width={480}
                          items={ccounts}
                          onChange={(v) => {
                            setValue("cCount", v);
                          }}
                          value={value}
                          validate={errors.cCount ? "error" : ""}
                          errorMessage={
                            errors.cCount ? errors.cCount.message : ""
                          }
                        />
                      )}
                      name="cCount"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={"T1 Standart"}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.t1Standard ? "error" : ""}
                          errorMessage={
                            errors.t1Standard ? errors.t1Standard.message : ""
                          }
                        />
                      )}
                      name="t1Standard"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={"T1"}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.t1 ? "error" : ""}
                          errorMessage={errors.t1 ? errors.t1.message : ""}
                        />
                      )}
                      name="t1"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={"T2"}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.t2 ? "error" : ""}
                          errorMessage={errors.t2 ? errors.t2.message : ""}
                        />
                      )}
                      name="t2"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={"T3"}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.t3 ? "error" : ""}
                          errorMessage={errors.t3 ? errors.t3.message : ""}
                        />
                      )}
                      name="t3"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <SelectBox
                          placeholder={t("dashboard:solution")}
                          options={solutions}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.solutions ? "error" : ""}
                          errorMessage={
                            errors.solutions ? errors.solutions.message : ""
                          }
                          isMulti={true}
                        />
                      )}
                      name="solutions"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:contractNo")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.contractNo ? "error" : ""}
                          inputType={"contractNo"}
                          errorMessage={
                            errors.contractNo ? errors.contractNo.message : ""
                          }
                        />
                      )}
                      name="contractNo"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("dashboard:accountType")}
                          items={accountTypes}
                          width={480}
                          onBlur={onBlur}
                          onChange={(v) => {
                            setValue("accountType", v);
                          }}
                          value={value}
                          validate={errors.accountType ? "error" : ""}
                          errorMessage={
                            errors.accountType ? errors.accountType.message : ""
                          }
                        />
                      )}
                      name="accountType"
                    />
                  </div>
                  <div className="w-full flex flex-col items-center gap-y-2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:authorizedName")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.contactName ? "error" : ""}
                          errorMessage={
                            errors.contactName ? errors.contactName.message : ""
                          }
                        />
                      )}
                      name="contactName"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:authorizedLastName")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.contactLastname ? "error" : ""}
                          errorMessage={
                            errors.contactLastname
                              ? errors.contactLastname.message
                              : ""
                          }
                        />
                      )}
                      name="contactLastname"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:authorizedMail")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.contactEmail ? "error" : ""}
                          inputType={"email"}
                          errorMessage={
                            errors.contactEmail
                              ? errors.contactEmail.message
                              : ""
                          }
                        />
                      )}
                      name="contactEmail"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <PhoneInputs
                          label={t("form:authorizedPhone")}
                          value={value}
                          onChange={onChange}
                          defaultCountry="TR"
                        />
                      )}
                      name="contactPhone"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("dashboard:taxOffice")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.taxOffice ? "error" : ""}
                          errorMessage={
                            errors.taxOffice ? errors.taxOffice.message : ""
                          }
                        />
                      )}
                      name="taxOffice"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("dashboard:taxNo")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.taxNo ? "error" : ""}
                          errorMessage={
                            errors.taxNo ? errors.taxNo.message : ""
                          }
                        />
                      )}
                      name="taxNo"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:shift")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.shift ? "error" : ""}
                          errorMessage={
                            errors.shift ? errors.shift.message : ""
                          }
                        />
                      )}
                      name="shift"
                    />
                  </div>
                </div>
                <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => deleteCompany(selectedCompany.id)}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                  >
                    {t("buttons:delete")}
                  </button>
                </div>
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedTab("companyInfo");
                    }}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:prev")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedTab("contractInfo");
                    }}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:next")}
                  </button>
                </div>
              </div>
            )}
            {selectedTab === "contractInfo" && (
              <div className="w-full">
                <div className="flex w-full gap-4">
                  <div className="w-full flex flex-col items-center justify-between gap-y-2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:user")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.user ? "error" : ""}
                          errorMessage={errors.user ? errors.user.message : ""}
                        />
                      )}
                      name="user"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:node")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.node ? "error" : ""}
                          errorMessage={errors.node ? errors.node.message : ""}
                        />
                      )}
                      name="node"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:department")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.department ? "error" : ""}
                          errorMessage={
                            errors.department ? errors.department.message : ""
                          }
                        />
                      )}
                      name="department"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:message")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.message ? "error" : ""}
                          errorMessage={
                            errors.message ? errors.message.message : ""
                          }
                        />
                      )}
                      name="message"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:notification")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.notification ? "error" : ""}
                          errorMessage={
                            errors.notification
                              ? errors.notification.message
                              : ""
                          }
                        />
                      )}
                      name="notification"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:junction")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.junction ? "error" : ""}
                          errorMessage={
                            errors.junction ? errors.junction.message : ""
                          }
                        />
                      )}
                      name="junction"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:externalOperation")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.externalOperation ? "error" : ""}
                          errorMessage={
                            errors.externalOperation
                              ? errors.externalOperation.message
                              : ""
                          }
                        />
                      )}
                      name="externalOperation"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:supplier")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.supplier ? "error" : ""}
                          errorMessage={
                            errors.supplier ? errors.supplier.message : ""
                          }
                        />
                      )}
                      name="supplier"
                    />
                  </div>
                  <div className="w-full flex flex-col items-center gap-y-2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:order")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.order ? "error" : ""}
                          errorMessage={
                            errors.order ? errors.order.message : ""
                          }
                        />
                      )}
                      name="order"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:operation")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.operation ? "error" : ""}
                          errorMessage={
                            errors.operation ? errors.operation.message : ""
                          }
                        />
                      )}
                      name="operation"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:embeddedServer")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.embeddedServer ? "error" : ""}
                          errorMessage={
                            errors.embeddedServer
                              ? errors.embeddedServer.message
                              : ""
                          }
                        />
                      )}
                      name="embeddedServer"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:stockCardOperation")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.stockCardOperation ? "error" : ""}
                          errorMessage={
                            errors.stockCardOperation
                              ? errors.stockCardOperation.message
                              : ""
                          }
                        />
                      )}
                      name="stockCardOperation"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:poll")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.poll ? "error" : ""}
                          errorMessage={errors.poll ? errors.poll.message : ""}
                        />
                      )}
                      name="poll"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:task")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.task ? "error" : ""}
                          errorMessage={errors.task ? errors.task.message : ""}
                        />
                      )}
                      name="task"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:stockCard")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.stockCard ? "error" : ""}
                          errorMessage={
                            errors.stockCard ? errors.stockCard.message : ""
                          }
                        />
                      )}
                      name="stockCard"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("form:attend")}
                          width={480}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.attend ? "error" : ""}
                          errorMessage={
                            errors.attend ? errors.attend.message : ""
                          }
                        />
                      )}
                      name="attend"
                    />
                  </div>
                  <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
                    <button
                      type="button"
                      onClick={() => deleteCompany(selectedCompany.id)}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                    >
                      {t("buttons:delete")}
                    </button>
                  </div>
                  <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedTab("contactInfo");
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                    >
                      {t("buttons:prev")}
                    </button>
                    <button
                      type={`${selectedCompany ? "button" : "submit"}`}
                      onClick={() => {
                        if (selectedCompany) {
                          setSelectedTab("nodeInfo");
                        }
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                    >
                      {t("buttons:next")}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "nodeInfo" && (
              <div className="flex w-full h-[560px]">
                <div className="flex w-full h-full justify-center">
                  {companyNodes.length > 0 ? (
                    <div className="w-full overflow-y-scroll no-scrollbar border border-gray-300 rounded-lg shadow-md relative overflow-x-scroll">
                      <div className="w-max justify-between flex h-11 bg-gray-50 sticky top-0 border-b border-x border-gray-300">
                        <span className="min-w-[72px] h-full flex items-center justify-center">
                          <div className="w-3 h-3 rounded-full border border-gray-300 shadow"></div>
                        </span>
                        {companyTableFilter.map((item, i) => (
                          <span
                            key={item.value}
                            className={`flex sticky top-0 justify-center gap-3 h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100 ${
                              item.checked || "hidden"
                            } `}
                          >
                            {item.checked ? t(`company:${item.value}`) : ""}
                            {item.checked ? (
                              <DownArrow className="w-3 h-3" />
                            ) : (
                              ""
                            )}
                          </span>
                        ))}
                      </div>
                      {companyNodes?.map((d) => (
                        <>
                          <div className="w-full h-12 flex ">
                            <span className="min-w-[72px] border-gray-200 min-h-[48px] flex items-center justify-center group-hover:bg-slate-50">
                              <span
                                style={{
                                  backgroundColor:
                                    d.deviceState === "NOT_CONNECTED"
                                      ? "#D92D00"
                                      : "#0E9384",
                                }}
                                className="w-3 h-3 rounded-full border border-gray-300 shadow-sm"
                              ></span>
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {selectedCompany.name}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.deviceId}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.deviceState}
                            </span>
                            <a
                              target={"_blank"}
                              href={`http://${d.ipAddress}`}
                              className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100"
                            >
                              {d.ipAddress}
                            </a>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.status}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.stmVersion}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.espVersion}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.rssi}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {String(d.softwareTest)}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {String(d.hardwareTest)}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.productDate}
                            </span>
                            <span className="flex justify-center h-full items-center min-w-[223px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                              {d.hardwareVersion}
                            </span>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className="mt-4">
                      <NoData
                        icon={<NodeIcon />}
                        text={t("dashboard:nodeText")}
                        header={t("dashboard:noNode")}
                      />
                    </div>
                  )}
                </div>
                <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => deleteCompany(selectedCompany.id)}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                  >
                    {t("buttons:delete")}
                  </button>
                </div>
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => setSelectedTab("contractInfo")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:prev")}
                  </button>
                  <button
                    type="button"
                    onClick={() => setSelectedTab("userList")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:next")}
                  </button>
                </div>
              </div>
            )}

            {selectedTab === "userList" && (
              <div className="flex w-full h-[600px]">
                <div className="flex w-full h-full justify-center">
                  {selectedCompany.employee.length > 0 ? (
                    <div className="w-full overflow-y-scroll no-scrollbar border border-gray-300 rounded-lg shadow-md relatives">
                      <div className="w-full justify-between flex h-11 bg-gray-50  sticky top-0 border-b border-x border-gray-300 pr-2">
                        {companyUserListTab.map((item, i) => (
                          <span
                            key={item.value}
                            className={`flex sticky top-0 justify-center gap-3 h-full items-center min-w-[140px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100 ${
                              item.checked || "hidden"
                            } `}
                          >
                            {item.checked ? t(`company:${item.value}`) : ""}
                            {/* {item.checked ? (
                              <DownArrow className="w-3 h-3" />
                            ) : (
                              ""
                            )} */}
                          </span>
                        ))}
                      </div>
                      {selectedCompany?.employee?.map((d) => {
                        return (
                          <>
                            <div className="w-full h-12 flex justify-between border-b-[1px] border-[#EAECF0]">
                              <div className="flex justify-center h-full items-center min-w-[140px] hover:bg-gray-100 py-4 px-6">
                                {d.avatar ? (
                                  <img
                                    className="flex h-8 w-8 flex-col justify-center items-center  rounded-full bg-[#C7B9DA]"
                                    src={generateFileUrl(d?.avatar)}
                                  />
                                ) : (
                                  <div
                                    className={
                                      "min-w-[32px] h-8 rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center text-white text-xs"
                                    }
                                  >
                                    {d?.name?.charAt(0).toUpperCase()}
                                  </div>
                                )}
                              </div>
                              <span className="flex justify-center h-full items-center min-w-[140px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                                {d?.name}
                              </span>
                              <span className="flex justify-center h-full items-center min-w-[140px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                                {d?.lastName}
                              </span>
                              <span className="flex justify-center h-full items-center min-w-[140px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100">
                                {d?.email}
                              </span>
                              <span
                                onClick={() => {
                                  setSelectedUser(d);
                                  setIsOpenCompanySelect(true);
                                }}
                                type="button"
                                className="flex justify-center h-full items-center min-w-[140px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100"
                              >
                                <AddUsers
                                  width={24}
                                  height={24}
                                  color="#667085"
                                />
                              </span>
                              <span
                                onClick={() => {
                                  setSelectedUser(d);
                                  setIsAlert(true);
                                }}
                                type="button"
                                className="flex justify-center h-full items-center min-w-[140px] text-xs text-black font-normal cursor-pointer hover:bg-gray-100"
                              >
                                <TrashIcons
                                  width={24}
                                  height={24}
                                  color="#667085"
                                />
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mt-4">
                      <NoData
                        icon={<NodeIcon />}
                        text={t("dashboard:nodeText")}
                        header={t("dashboard:noNode")}
                      />
                    </div>
                  )}
                </div>
                <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => deleteCompany(selectedCompany.id)}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                  >
                    {t("buttons:delete")}
                  </button>
                </div>
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => setSelectedTab("nodeInfo")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:prev")}
                  </button>
                  <button
                    type="button"
                    onClick={() => setSelectedTab("logRecord")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:next")}
                  </button>
                </div>
              </div>
            )}
            {selectedTab === "logRecord" && (
              <div>
                <div className="w-full flex flex-col gap-y-2 justify-between  border border-gray-300 rounded-lg py-3 px-4 overflow-y-scroll no-scrollbar shadow">
                  {logContent.map((d) => (
                    <div className="flex items-center gap-2 group select-none w-1/2 pl-3">
                      <DownIcon className="h-2.5 w-2.5 group-hover:-rotate-90 transition-[300ms]" />
                      <p className="text-sm text-gray-500 group-hover:text-gray-800">
                        {d.content}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => deleteCompany(selectedCompany.id)}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                  >
                    {t("buttons:delete")}
                  </button>
                </div>
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => setSelectedTab("userList")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:prev")}
                  </button>
                  <button
                    type="submit"
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:save")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <AdminModal
        modalIsOpen={isOpenCompanySelect}
        closeModal={() => setIsOpenCompanySelect(false)}
        width={771}
        height={421}
        title={t("dashboard:chooseCompany")}
        children={
          <ChooseCompany
            setIsOpenCompanySelect={setIsOpenCompanySelect}
            user={selectedUser}
            getOneCompany={getOneCompany}
          />
        }
      />
      <AlertModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        text={t("dashboard:deleteUserText")}
        icon={<BsTrash className="text-2xl" />}
        header={`${selectedUser.name} ${selectedUser.lastName}`}
        label={t("buttons:delete")}
        applyFunction={() => deleteUser()}
      />
    </>
  );
};

export default CompanyForm;
