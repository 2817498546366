import React, { useEffect, useState } from "react";
import { t } from "i18next";
import i18next from "i18next";
import moment from "moment";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { nodeTableFilter, nodeListSearchTypes } from "../../static/constants";
import { companyService, softwareService, nodeService } from "../../services";
import { DownArrow, PlusIcon, UpIcon, Update } from "../../assets/icons";
import {
  AddNewNod,
  SearchInput,
  Loading,
  ChangeLanguage,
  SelectSoftwareModal,
  NewAddButton,
  EditNode,
  AdminModal,
  FilterModal,
  SoftwareUpdateInfoModal,
} from "../../components";
import { useNavigate } from "react-router-dom";
const Nodes = () => {
  const [selectedNode, setSelectedNode] = useState();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [addNodModal, setAddNodModal] = useState(false);
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const [updateReportModal, setUpdateReportModal] = useState(false);
  const [updateReportData, setUpdateReportData] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [selectLanguage, setSelectLanguage] = useState(i18next.language);
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [companyNodes, setCompanyNodes] = useState([]);
  const [tempCompanyNodes, setTempCompanyNodes] = useState([]);
  const [open, setOpen] = useState([]);
  const [selectSoftwareModal, setSelectSoftwareModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortingValue, setSortingValue] = useState("");
  const [filterVal, setFilterVal] = useState("all");

  const navigate = useNavigate();
  const channel = useChannel("adminNodeList");
  const handleCompanies = async () => {
    await companyService
      .listCompanies()
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  
  const handleCompanyNodes = async (refresh = true) => {
    setLoading(refresh);
    await nodeService
      .listNodesByCompany()
      .then((res) => {
        setOpen(res.data.map(() => true));

        
        setCompanyNodes(
          res?.data?.sort((a, b) => b?.nodes?.length - a?.nodes?.length)
        );
        setTempCompanyNodes(
          res?.data?.sort((a, b) => b?.nodes?.length - a?.nodes?.length)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  const handleSoftwares = async () => {
    await softwareService
      .getSoftwares()
      .then((res) => {
        setSoftwares(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelect = (companyId, nodeId) => {
    if (selectedNodes.length > 0) {
      let index = selectedNodes.findIndex((x) => x.companyId === companyId);
      if (index !== -1) {
        let index2 = selectedNodes[index].nodes.findIndex((x) => x === nodeId);
        if (index2 !== -1) {
          const newNodes = (selectedNodes[index].nodes = selectedNodes[
            index
          ].nodes.filter((item) => item !== nodeId));
          if (newNodes.length === 0) {
            selectedNodes.splice(index, 1);
            setSelectedNodes([...selectedNodes]);
          } else {
            setSelectedNodes([...selectedNodes]);
          }
        } else {
          selectedNodes[index].nodes.push(nodeId);
          setSelectedNodes([...selectedNodes]);
        }
      } else {
        setSelectedNodes([
          ...selectedNodes,
          {
            companyId: companyId,
            nodes: [nodeId],
          },
        ]);
      }
    } else {
      setSelectedNodes([
        {
          companyId: companyId,
          nodes: [nodeId],
        },
      ]);
    }
  };
  const handleSearch = () => {
    if (searchVal) {
      const mapper = tempCompanyNodes.map((company) => {
        const searchMapper = nodeListSearchTypes.map((type) => {
          return company.nodes
            .map((node) => {
              let name;
              if (type === "metadata") {
                name = `${node?.metadata?.brand} ${node?.metadata?.model}`;
              } else {
                name = `${node[type]}`;
              }
              const check = name
                .replace(/^\./g, "")
                .toLowerCase()
                .includes(searchVal.toLowerCase());
              if (check) {
                return node;
              } else {
                return null;
              }
            })
            .filter((item) => item !== null)
            .flat();
        });
        const filteredNodes = searchMapper.flat();
        return {
          ...company,
          nodes: [...new Set(filteredNodes)],
        };
      });
      setCompanyNodes(mapper.filter((item) => item.nodes.length > 0));
    } else {
      setCompanyNodes(tempCompanyNodes);
    }
  };
  const handleMultipleSelect = (company) => {
    const finder = selectedNodes.find((item) => item.companyId === company.id);
    if (finder) {
      const newSelectedNodes = selectedNodes.map((item) => {
        if (item.companyId === company.id) {
          return {
            ...item,
            nodes: company.nodes.map((item) => item.deviceId),
          };
        } else {
          return item;
        }
      });
      setSelectedNodes(newSelectedNodes);
    } else {
      setSelectedNodes([
        ...selectedNodes,
        {
          companyId: company.id,
          nodes: company.nodes.map((item) => item.deviceId),
        },
      ]);
    }
  };
  const handleDeselect = (company) => {
    const newSelectedNodes = selectedNodes.filter(
      (item) => item.companyId !== company.id
    );
    setSelectedNodes(newSelectedNodes);
  };
  const handleSort = (value) => {
    const mapper = tempCompanyNodes.map((company) => {
      const sortedNodes = company.nodes.sort((a, b) => {
        if (value == "espVersion" || value == "stmVersion") {
          let aVersion = Number(a[value].slice(a[value].lastIndexOf("v") + 1))
            ? Number(a[value].slice(a[value].lastIndexOf("v") + 1))
            : a[value] == "v0.00"
            ? 0
            : a[value];
          let bVersion = Number(b[value].slice(b[value].lastIndexOf("v") + 1))
            ? Number(b[value].slice(b[value].lastIndexOf("v") + 1))
            : b[value] == "v0.00"
            ? 0
            : b[value];
          return aVersion - bVersion;
        } else {
          let aVersion = Number(a[value]) ? Number(a[value]) : a[value];
          let bVersion = Number(b[value]) ? Number(b[value]) : b[value];
          return aVersion - bVersion;
        }
      });
      if (sortingValue == value) {
        setSortingValue("");
      }
      return {
        ...company,
        nodes: sortingValue == value ? sortedNodes.reverse() : sortedNodes,
      };
    });
    setCompanyNodes(mapper);
  };
  useEvent(channel, "update-node-data", (socketEvent) => {
    handleCompanyNodes(false);
    if (searchVal) {
      handleSearch();
    } else {
      setCompanyNodes(tempCompanyNodes);
    }
  });
  useEffect(() => {
    if (searchVal) {
      handleSearch();
    } else {
      setCompanyNodes(tempCompanyNodes);
    }
  }, [searchVal, setSearchVal]);
  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);
  useEffect(() => {
    handleSoftwares();
    handleCompanies();
    handleCompanyNodes();
  }, []);
  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex flex-row items-center justify-between">
          <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
            {t("auth:nodeList")}
          </span>
          <div className="flex gap-4 items-center">
          <NewAddButton
              width={140}
              label={
               "Graph View"
              }
              icon={<Update className="w-5 h-5 text-gray-600" />}
              onClick={() => {
                navigate("/dashboard/system-node-chart")
              }}
            />
            <NewAddButton
              width={240}
              label={
                selectedNodes?.length == 0
                  ? t("buttons:updateAllDevices")
                  : t("buttons:updateSelectedDevices")
              }
              icon={<Update className="w-5 h-5 text-gray-600" />}
              onClick={() => {
                setSelectedNode(null);
                if (selectedNodes.length == 0) {
                  setSelectedNodes(
                    companyNodes.map((item) => {
                      return {
                        companyId: item.id,
                        nodes: item.nodes.map((item2) => {
                          return item2.deviceId;
                        }),
                      };
                    })
                  );
                }
                setSelectSoftwareModal(true);
              }}
            />
            <NewAddButton
              width={170}
              label={t("buttons:addNewNode")}
              primary={true}
              icon={
                <PlusIcon
                  width={16}
                  height={16}
                  className="w-4 h-4 text-white"
                />
              }
              onClick={() => {
                setSelectedNode(null);
                setAddNodModal(true);
              }}
            />
            <SearchInput
              width={200}
              setSearchVal={setSearchVal}
              placeholder={t("auth:search")}
            />
            <ChangeLanguage
              setVisibleLanguage={setVisibleLanguage}
              setSelectedLanguage={setSelectLanguage}
              selectedLanguage={selectLanguage}
              visibleLanguage={visibleLanguage}
            />
          </div>
        </div>
        <div className="h-px w-full bg-secondary-200 drop-shadow-md mt-3" />
        <div className="max-h-[90%] h-[90%] flex flex-col justify-between mt-6 gap-y-6 overflow-y-scroll scrollbar-hide">
          {loading ? (
            <div className="flex items-center w-full justify-center h-full">
              <div className="w-12 h-12">
                <Loading />
              </div>
            </div>
          ) : (
            companyNodes?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-between items-center w-full pb-2">
                    <div
                      onClick={() => {
                        setOpen((pOpen) =>
                          pOpen.map((o, i) => {
                            if (i === index) return !o;
                            return o;
                          })
                        );
                      }}
                      className="w-[90%] cursor-pointer flex items-center overflow-hidden gap-x-2"
                    >
                      <span className="font-medium text-base text-[#1D2939]">
                        {item?.name}
                      </span>
                      <span className="bg-primary-50 rounded-2xl font-medium text-primary-600 text-xs px-2 py-0.5">
                        {item?.nodes?.length}
                      </span>
                    </div>
                    <div className="w-full flex flex-row items-center justify-end gap-x-3">
                      {selectedNodes?.findIndex(
                        (x) => x.companyId === item.id
                      ) !== -1 && (
                        <div onClick={() => handleDeselect(item)}>
                          <label className="font-medium text-primary-600 cursor-pointer text-sm leading-5">
                            {t("software:deselect")}
                          </label>
                        </div>
                      )}
                      {item?.nodes?.length > 0 && (
                        <div onClick={() => handleMultipleSelect(item)}>
                          <label className="font-medium text-primary-600 cursor-pointer text-sm leading-5">
                            {t("software:updateAllDevicesForThisCompany")}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  {open[index] && (
                    <div className="border scrollbar-hide overflow-x-scroll rounded-lg border-gray-300">
                      <div className="w-full justify-between flex h-11  bg-gray-50  border-b z-10">
                        <span className="min-w-[72px] h-full flex items-center justify-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100 border-r">
                          {t(`company:status`)}
                        </span>
                        {nodeTableFilter.map((item, i) => (
                          <span
                            onClick={() => {
                              setSortingValue(item.value);
                              handleSort(item.value);
                            }}
                            key={item.value}
                            className={`flex justify-center gap-3 h-full items-center w-full text-xs text-gray-500 cursor-pointer hover:bg-gray-100 border-r`}
                          >
                            {t(`company:${item.value}`)}
                            <DownArrow
                              width={12}
                              height={12}
                              className="w-3 h-3"
                            />
                          </span>
                        ))}
                      </div>
                      <div className="max-h-[500px] w-full overflow-y-scroll scrollbar-hide h-full">
                        {item?.nodes?.map((node, i) => {
                          return (
                            <div
                              key={i}
                              className="w-full h-[72px] relative overflow-x-scroll"
                            >
                              <ul
                                className="w-full h-[72px] flex items-center border-b border-gray-200 group"
                                key={i}
                              >
                                <li className="min-w-[72px] border-r border-gray-200 min-h-[72px] flex items-center justify-center group-hover:bg-slate-50">
                                  <span
                                    style={{
                                      backgroundColor:
                                        node.deviceState === "NOT_CONNECTED"
                                          ? "#D92D00"
                                          : "#0E9384",
                                    }}
                                    className="w-3 h-3 rounded-full border border-gray-300 shadow-sm"
                                  ></span>
                                </li>
                                {nodeTableFilter.map((d, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        setSelectedNode(node);
                                        if (
                                          !(
                                            d.value == "ipAddress" ||
                                            d.value == "espVersion" ||
                                            d.value == "update"
                                          )
                                        ) {
                                          setAddNodModal(true);
                                        }
                                      }}
                                      className="h-full border-r select-none w-full text-center max-w-[223px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                                    >
                                      {d.value == "ipAddress" ? (
                                        <a
                                          target={"_blank"}
                                          href={`http://${node[d.value]}`}
                                          className="flex items-center gap-1"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {node[d.value]}
                                          <UpIcon width={10} height={10} />
                                        </a>
                                      ) : d.value == "brand" ? (
                                        <div className="text-xs">
                                          {node?.metadata?.brand
                                            ? node?.metadata?.brand
                                            : "NONE"}
                                          {" / "}
                                          {node?.metadata?.model
                                            ? node?.metadata?.model
                                            : "NONE"}
                                        </div>
                                      ) : d.value == "update" ? (
                                        <div
                                          onClick={() => {
                                            handleSelect(
                                              item.id,
                                              node.deviceId
                                            );
                                          }}
                                          className="text-xs w-full h-full flex items-center justify-center cursor-pointer"
                                        >
                                          <input
                                            onChange={() => {}}
                                            checked={selectedNodes.some(
                                              (n) =>
                                                n.companyId === item.id &&
                                                n.nodes.includes(node.deviceId)
                                            )}
                                            type="checkbox"
                                            className="w-4 h-4 rounded-md border text-primary-600 bg-primary-100 border-primary-50 accent-primary-500"
                                          />
                                        </div>
                                      ) : d.value == "createdDate" ? (
                                        <span>
                                          {moment(node?.createdDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      ) : d.value == "updatedDate" ? (
                                        <span>
                                          {moment(node?.updatedDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      ) : (
                                        node[d.value] && node[d.value]
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
      <AdminModal
        modalIsOpen={addNodModal}
        closeModal={() => setAddNodModal(false)}
        height={!selectedNode ? "650px" : "650px"}
        title={!selectedNode ? t("buttons:addNewNode") : t("form:editNode")}
        children={
          <AddNewNod
            setIsOpen={setAddNodModal}
            companies={companies}
            setSelectedNode={setSelectedNode}
            selectedNode={selectedNode}
            handleNodes={handleCompanyNodes}
            companyNodes={tempCompanyNodes}
            children={<EditNode />}
          />
        }
      />
      <AdminModal
        modalIsOpen={selectSoftwareModal}
        closeModal={() => {
          setSelectSoftwareModal(false);
          setSelectedNodes([]);
        }}
        height={"550px"}
        width={"500px"}
        title={t("software:updateDevice")}
        children={
          <SelectSoftwareModal
            setIsOpen={setSelectSoftwareModal}
            selectedNodes={selectedNodes}
            softwares={softwares}
            handleCompanyNodes={handleCompanyNodes}
            setSelectedNodes={setSelectedNodes}
            setUpdateReportData={setUpdateReportData}
            setUpdateReportModal={setUpdateReportModal}
          />
        }
      />
      <AdminModal
        modalIsOpen={updateReportModal}
        closeModal={() => {
          setUpdateReportModal(false);
        }}
        height={"780px"}
        width={"1000px"}
        title={t("software:updateReport")}
        children={
          <SoftwareUpdateInfoModal
            updateReportData={updateReportData}
            setIsOpen={setUpdateReportModal}
            setUpdateReportData={setUpdateReportData}
          />
        }
      />
      <FilterModal
        isOpen={filterModalIsOpen}
        onRequestClose={() => setFilterModalIsOpen(false)}
        filterVal={filterVal}
        setFilterVal={setFilterVal}
        companies={[
          { label: "All", value: "all" },
          ...companies?.map((com) => {
            return {
              value: com.id,
              label: com.name,
            };
          }),
        ]}
      />
    </>
  );
};

export default Nodes;
