const tr = {
  auth: {
    login: "Giriş Yap",
    search: "Ara",
    language: "Dil",
    nodeList: "Node Listesi",
    newNodeList: "Yeni Node Listesi",
    nodeListChartView: "Node Listesi Grafik Görünümü",
    userList: "Kullanıcı Listesi",
    settings: "Ayarlar",
    industries: "Endüstri",
    roles: "Roller",
    permission: "Yetkiler",
    statuses: "Durumlar",
    sensors: "Sensörler",
    softwares: "Yazılımlar",
    users: "Kullanıcılar",
    ccount: "C-Count",
  },
  dashboard: {
    company: "Firma",
    companyName: "Firma ismi",
    companyInfo: "Firma Bilgileri",
    contactInfo: "İletişim Bilgileri",
    contractInfo: "Sözleşme Bilgileri",
    registerNode: "Node Kayıt",
    nodeInfo: "Node Bilgisi",
    userList: "Kullanıcı Listesi",
    maintanence: "Arıza ve Bakım",
    liveStatus: "Anlık Durum",
    logRecord: "Log Kaydı",
    addNewRoles: "Yeni Rol Ekle",
    addNewStatus: "Yeni Durum Ekle",
    addNewPermission: "Yeni Yetki Ekle",
    country: "Ülke",
    industry: "Endüstri",
    image: "Resim",
    name: "İsim",
    lastName: "Soy Isim",
    email: "E-mail",
    addToAnotherCompany: "Başka Bir Şirkete Ekle",
    chooseCompanyContent: "Kullanıcıyı eklemek istediğiniz şirketleri seçin.",
    chooseCompany: "Şirket(ler)i seçin",
    surname: "Soyadı",
    city: "Şehir",
    contactPhone: "İletişim No",
    nodeLimit: "Node Limiti",
    userLimit: "Kullanıcı Limiti",
    solution: "Çözümler",
    accountType: "Hesap Tipi",
    adress: "Adres",
    contactNo: "İletişim No",
    taxOffice: "Vergi Dairesi",
    taxNo: "Vergi No",
    phone: "Telefon No",
    status: "Durum",
    contactName: "İletişim Adı",
    contactEmail: "İletişim Adresi",
    limitZone: "Limit Zone",
    device: "Cihaz Durumu",
    stm: "STM Versiyonu",
    esp: "ESP Versiyonu",
    nodeList: "Node Listesi",
    newNodeList: "Yeni Node Listesi",
    nodeListChartView: "Node Listesi Grafik Görünümü",
    logRecord: "Log Kaydı",
    nodeText: "Firmaya Kayıtlı Node Bulunmamaktadır.",
    noNode: "Node Bulunmamaktadır",
    software: "ESP",
    deleteUserText: "Kullanıcıyı silmek istediğinize emin misiniz?",
  },
  buttons: {
    start: "Başlat",
    login: "Giriş",
    next: "Sonraki",
    prev: "Önceki",
    add: "Ekle",
    delete: "Sil",
    addNew: "Yeni Ekle",
    change: "Değiştir",
    stop: "Vazgeç",
    save: "Kaydet",
    addNewIndustry: "Yeni Endüstri Ekle",
    addNewNode: "Yeni Node Ekle",
    addPermission: "Yeni Yetki Ekle",
    addNewRole: "Yeni Rol Ekle",
    addNewStatus: "Yeni Durum Ekle",
    removeFilter: "Filtreyi Kaldır",
    closeButton: "Kapat",
    updateDevices: "Cihazları Güncelle",
    updateAllDevices: "Tüm Cihazları Güncelle",
    updateSelectedDevices: "Seçili Cihazları Güncelle",
    addNewUser: "Yeni User Ekle",
    send: "Gönder",
    applyFilter: "Filtreyi Uygula",
    sortBy: "Sırala",
    removeAllFilters: "Tüm Filtreleri Kaldır",
    removeFilter: "Filtreyi Kaldır",
  },
  companyContent: {
    active: "Aktif",
    yearlySubscription: "Yıllık Abonelik",
    membershipStatus: "Üyelik Durumu",
  },
  company: {
    addNewCompany: "Yeni Firma Ekle",
    editCompany: "Firma Düzenle",
    accountType: "Hesap Tipi",
    membershipTime: "Üyelik Süresi",
    purchasedPackages: "Satın Alınan Paketler",
    industry: "Endüstri",
    industryName: "Endüstri Adı (TR)",
    industryNames: "Endüstri Adı (EN)",
    permissionName: "Yetki Adı (TR)",
    permissionNames: "Yetki Adı (EN)",
    roleName: "Rol Adı (TR)",
    key: "Anahtar",
    role: "Rol",
    status: "Durum",
    nameEn: "İsim (EN)",
    nameTr: "İsim (TR)",
    companies: "Firmalar",
    roleNames: "Rol Adı (EN)",
    roleKey: "Rol Anahtarı",
    statusName: "Durum İsmi",
    statusCode: "Durum Kodu",
    statusColor: "Durum Rengi",
    permissionList: "Yetki Listesi",
    timeZone: "Saat Dilimi",
    fiscalYear: "Mali Yıl Başlangıç Tarihi",
    fiscalYearEnd: "Mali Yıl Bitiş Tarihi",
    authorizedLastName: "Yetkili Soyadı",
    contractNo: "Sözleşme No",
    cardVersion: "Elektronik Kart Versiyonu",
    cardSerialNo: "Elektronik Kart Seri No",
    softwareTest: "Yazılım Testi",
    espVersionNo: "Esp Versiyonu",
    stmVersionNo: "Stm Versiyonu",
    hardwareTest: "Donanım Testi",
    hardwareVersion: "Donanım Versiyonu",
    faultRepairDate: "Arıza Onarım Tarihi",
    fault: "Arıza Nedeni",
    productionDate: "Üretim Tarihi",
    faultDescription: "Arıza Tanımı",
    sendingMethod: "Gönderim Şekli",
    sendingDate: "Gönderim Tarihi",
    transactionsMade: "Yapılan İşlem",
    replacedModule: "Değiştirilen Parçalar",
    faultNotification: "Arıza Bildirim Tarihi",
    previousRecord: "Geçmiş Kayıt Bulunamadı",
    deviceState: "Cihaz Durumu",
    ipAddress: "IP Adresi",
    companyList: "Firma Listesi",
    companyName: "Firma",
    nodeId: "Node ID",
    deviceId: "Cihaz ID",
    nodeSnapLimit: "Düğüm Snap Limiti",
    status: "Durum",
    stmVersion: "STM Versiyonu",
    espVersion: "ESP Versiyonu",
    rssi: "RSSI",
    productDate: "Üretim Tarihi",
    general: "Genel",
    permissionKey: "Yetki Anahtarı",
    brand: "Bağlı Makine",
    faultReporter: "Bildirimi Yapan",
    faultRepairman: "Tamir Eden",
    faultApprover: "Onaylayan",
    description: "Açıklama",
    image: "Resim",
    name: "İsim",
    lastName: "Soy Isim",
    email: "E-mail",
    addToAnotherCompany: "Başka Bir Şirkete Ekle",
    deleteUser: "Delete User",
    update: "Güncelle",
    updateStatus: "Güncelleme Durumu",
    company: "Firma",
    reason: "Sebep",
    avatar: "Avatar",
    password: "Şifre",
    passwordRepeat: "Şifre Tekrar",
    electronicCardSerialNo: "Elektronik Kart Seri No",
    oldVersion: "Eski Versiyon",
    newVersion: "Yeni Versiyon",
    type: "Tip",
    date: "Tarih",
    createdDate: "Oluşturulma Tarihi",
    updatedDate: "Güncellenme Tarihi",
  },
  form: {
    edit: "Düzenle",
    selectFile: "Dosya Seç",
    removePicture: "Resmi Kaldır",
    selectDate: "Tarih Seçin",
    startAnd: "Mali Yıl Başlangıç ve Bitiş Tarihi",
    authorizedName: "Yetkili Adı",
    companyPhone: "Firma Tel. Numarası",
    authorizedMail: "Yetkili E-mail",
    authorizedPhone: "Yetkili Numarası",
    timePeriod: "Zaman Dilimi",
    equipmentCredit: "Ekipman Kredisi",
    personalCredit: "Personel Kredisi",
    sortFilter: "Filtre",
    editNode: "Node Düzenle",
    node: "Node Limiti",
    user: "Kullanıcı Limiti",
    junction: "Junction Limiti",
    message: "Mesaj Limiti",
    operation: "Operasyon Limiti",
    poll: "Anket Limiti",
    task: "Görev Limiti",
    department: "Departman Limiti",
    notification: "Bildirim Limiti",
    embeddedServer: "Embedded Server Limiti",
    stockCardOperation: "Stok Kartı Operasyon Limiti",
    externalOperation: "Dış Operasyon Limiti",
    supplier: "Tedarikçi Limiti",
    order: "Sipariş Limiti",
    stockCard: "Stok Kartı Limiti",
    attend: "Attend Limiti",
    shift: "Shift Limiti",
  },
  validation: {
    empty: "Boş bırakılamaz",
    string: "Girdi stirng formatında olmalıdır",
    email: "Girdi email formatında olmalıdır",
    number: "Girdi sayı formatında olmalıdır",
    max30: "30 bu input için maksimum değerdir",
    min0: "0 bu input için minimum değerdir",
    date: "Girdi tarih formatında olmalıdır",
  },
  fileInput: {
    profilSubTitle: "Profil resmi eklemek için tıklayın.",
    uploadHeaderPicture: "Profil resmi",
    documentSubTitle: "Dosya yüklemek için tıklayınız.",
    uploaderHeaderDoc: "Dosya",
    uploadAvatar: "Resim yükle",
    changePic: "Resim değiştir",
    cropPicture: "Resim kırp",
    removePicture: "Resmi kaldır",
    uploadFile: "Belge yükle",
    removeFile: "Yeninden Seç",
    //Equipment
    equipmentPicture: "Ekipman resmi",
    equipSubTitle: "Ekipman resmi eklemek için tıklayınız.",
  },
  routes: {
    industries: "Endüstri",
    company: "Firma",
    nodeList: "Node Listesi",
    newNodeList: "Yeni Node Listesi",
    nodeListChartView: "Node Listesi Grafik Görünümü",
    roles: "Roller",
    permission: "Yetkiler",
    settings: "Ayarlar",
    statuses: "Durumlar",
    sensors: "Sensörler",
    softwares: "Yazılımlar",
    users: "Kullanıcılar",
    adminUsers: "Adminler",
    embeddedServerSettings: "Kayıtlı Sensör Ayar...",
  },
  sensor: {
    addNewSensor: "Yeni Sensör Ekle",
    addNewUnit: "Yeni Birim Ekle",
    name: "Sensör Adı",
    type: "Sensör Tipi",
    unitNameShort: "Birim Kısaltma",
    unitName: "Birim Adı",
  },
  status: {
    addNewStatus: "Yeni Durum Ekle",
    addNewDescription: "Yeni Açıklama Ekle",
    name: "Durum Adı",
    code: "Durum Kodu",
    descriptionEn: "Açıklama (EN)",
    descriptionTr: "Açıklama (TR)",
  },
  test: {
    testTypes: "Test Türleri",
    startTest: "Yeni Test Başlat",
    newTest: "Yeni Test Tipi Oluştur",
    testName: "Test Adı",
    create: "Oluştur",
    tests: "Testler",
    result: "Sonuç",
  },
  software: {
    softwares: "Yazılımlar",
    addNewSoftware: "Yazılım Ekle",
    editSoftware: "Yazılım Düzenle",
    softwareName: "Yazılım Adı",
    softwareVersion: "Yazılım Versiyonu",
    softwareDate: "Yazılım Tarihi",
    updateDevice: "Cihaz Güncelle",
    serialNo: "Seri No",
    selectAll: "Tümünü Seç",
    updateAll: "Tümünü Güncelle",
    selectSoftware: "Yazılım Seç",
    fieldTestApproval: "Saha Test Onayı",
    testEngineerApproval: "Test Mühendisi Onayı",
    softwareArchitectureApproval: "Yazılım Mimarisi Onayı",
    confirmApproval: "Onayla",
    confirmApprovalHeader: "Yazılımı Onaylamak İstediğinize Emin Misiniz?",
    confirm: "Onayla",
    deleteSoftware: "Yazılım Sil",
    deleteSoftwareHeader: "Yazılımı Silmek İstediğinize Emin Misiniz?",
    delete: "Sil",
    version: "Versiyon",
    type: "Tip",
    description: "Açıklama",
    updateSelectedDevices: "Seçili Cihazları Güncelle",
    updateAllDevicesForThisCompany: "Firma Cihazlarını Güncelle",
    softwareUpdated: "Yaılım Güncellendi",
    softwareUpdateFailed: "Yazılım Güncelleme Başarısız",
    softwareDeleted: "Yazılım Silindi",
    softwareDeleteFailed: "Yazılım Silme Başarısız",
    softwareApproved: "Yazılım Onaylandı",
    softwareApproveFailed: "Yazılım Onaylama Başarısız",
    softwareAdded: "Yazılım Eklendi",
    softwareAddFailed: "Yazılım Ekleme Başarısız",
    softwareUpdateSent: "Yazılım Güncelleme İsteği Gönderildi",
    updateReport: "Güncelleme Raporu",
    success: "Başarılı",
    failed: "Başarısız",
    updateNextTime: "Sonra Güncelle",
    updateFailed: "Güncelleme Başarısız",
    deselect: "Seçimi Kaldır",
  },
  ccount: {
    addNewCCount: "Yeni C-Count Ekle",
    editCCount: "C-Count Düzenle",
    zone: "Bölge",
    value: "Değer",
  },
  notification: {
    sendNotification: "Bildirim Gönder",
    notificationTr: "Bildirim (TR)",
    notificationEn: "Bildirim (EN)",
    targetCompanies: "Hedef Firmalar",
  },
};

export default tr;
